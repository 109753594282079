import { 
         Container,
         Paper,
         ListItem,
         ListItemText,
         Avatar,
         Alert,
         Box,
         Button
       } 
     from "@mui/material";
import React from "react";
import { Api,ImageApi } from "../../process/api";
import { useHistory } from "react-router";
import EditProfile from "./profileEdit";

export default function Profile(){
    const history = useHistory()
    const [data, setData] = React.useState([])
    const [refrsh, setRefresh] = React.useState()
    React.useEffect(()=>{
        fetch(Api+'profileInfo&email='+localStorage.getItem('email'))
        .then(Response=>Response.json())
        .then(data=>setData(data))

    },[refrsh])

    const handleClick = ()=>{
        history.push('EditProfile',data)
    }

    const child = ()=>{
        setRefresh(Math.random())
    }
    return(
        <Container component={Paper}>
        
        {
            data.length > 0 ? (
                
               <Box>
                {
                    data[0].status != 'active' ? (
                        <Alert severity="warning">Your profile isn't completed yet</Alert>
                    ) : ''
                }
                   <ListItem>
                        <Avatar 
                            src={ImageApi+data[0].profile}
                        />
                            
                      
                        <ListItemText 
                            primary={data[0].name}
                            secondary={data[0].department}
                        />
                        {/* <Button onClick={handleClick} size="small">
                            edit 
                        </Button> */}
                        <EditProfile onSave={child} data={data[0]} />
                   </ListItem>
                   <ListItem>
                        <ListItemText 
                            primary="Join at"
                        />
                        <ListItemText 
                            primary={data[0].joined}
                        />
                   </ListItem>
                   <ListItem>
                        <ListItemText 
                            primary="Visiting Charge"
                        />
                        <ListItemText 
                            primary={data[0].price}
                        />
                   </ListItem>
                   <ListItem>
                        <ListItemText 
                            primary="Contact No"
                        />
                        <ListItemText 
                            primary={data[0].ph_no}
                        />
                   </ListItem>
                   <ListItem>
                        <ListItemText 
                            primary="Email"
                        />
                        <ListItemText 
                            primary={data[0].email}
                        />
                   </ListItem>
                   <ListItem>
                        <ListItemText 
                            primary="Qualification"
                        />
                        <ListItemText 
                            primary={data[0].qualification}
                        />
                   </ListItem>
                   <ListItem>
                        <ListItemText 
                            primary="Designation"
                        />
                        <ListItemText 
                            primary={data[0].designation}
                        />
                   </ListItem>
                   <ListItem>
                        <ListItemText 
                            primary="Gender"
                        />
                        <ListItemText 
                            primary={data[0].gender}
                        />
                   </ListItem>
                   <ListItem>
                        <ListItemText 
                            primary="About"
                            secondary={data[0].about}
                        />
                   </ListItem>
               </Box>
            ) : (
                <div>

                </div>
            )
        }
        </Container>
    )
}