import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './components/home';
import Login from './components/auth/login';
import {useHistory} from 'react-router-dom'
function App() {
  const history = useHistory()
  const [authState, setAuthState] = React.useState(false)
  React.useEffect(()=>{
    const auth = localStorage.getItem('authnticated')
    if(!auth){
      history.push('/Login')
    }else{
      history.push('/Home')
    }
  })
  return (
    <div>

    </div>
  );
}

export default App;
