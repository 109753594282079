import React from 'react'
import {
    Container,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Paper,
    Snackbar,
    Alert,
    LinearProgress
} from '@mui/material'
import { Api } from '../../process/api'
import { useHistory } from 'react-router'
import SnakBar from '../../consts/massage'

export default function ForgotPass(){
    const [data, setData] = React.useState({})
    const [error, setError] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [otp,setOtp] = React.useState(Math.floor(1000 + Math.random() * 9000))
    const [sendStatus, setSendStatus] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [massg, setMassg] = React.useState({})
    const history = useHistory()
    const handleSend = ()=>{
        if(validateEmail()){
            setLoading(true)
            fetch(Api+'sendOtp',{
                method : 'POST',
                body : JSON.stringify({
                    otp : otp,
                    email : email
                })
            })
            .then(res=>res.json())
            .then(data=>{
                setLoading(false)
                if(data.status === 201){
                    setSendStatus(true)
                }else{
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
            })
            .catch(err=>{
                    setLoading(false)
                    setMassg({
                        open : true,
                        massg : 'Faild to connect to server',
                        severity : 'error'
                    })
            })
        }
        
    }

    const validateEmail = ()=>{
        let valid = true
        if(!email){
            setMassg({
                open : true,
                massg : "Please enter an email address",
                severity : 'error'
            })
            valid = false
        }

        return valid
    }

    const handleChange = (e)=>{
        setData({
            ...data,
            [e.target.name] : e.target.value
        })
    }

    const validate = ()=>{
        let valid = true

        if(!data.otp){
            valid = false
            setMassg({
                open : true,
                massg : 'Please enter otp',
                severity : 'error'
            })
        }else if(data.otp != otp){
            valid = false
            setMassg({
                open : true,
                massg : 'Please enter a valid otp',
                severity : 'error'
            })
        }
        if(!data.new_pass){
            valid = false
            setMassg({
                open : true,
                massg : 'Please enter a password',
                severity : 'error'
            })
        }
        if(!data.c_pass){
            valid = false
            setMassg({
                open : true,
                massg : 'Please confirm your password',
                severity : 'error'
            })
        }else if(data.new_pass != data.c_pass){
            setMassg({
                open : true,
                massg : 'password not matched',
                severity : 'error'
            })
        }

        return valid
        
    }

    const finalSubmit = ()=>{
        if(validate()){
            setLoading(true)
            fetch(Api+'ChangeAmbaPass',{
                method : 'POST',
                body : JSON.stringify({
                    email : email,
                    pass : data.new_pass
                })
            })
            .then(res=>res.json())
            .then(data=>{
                setLoading(false)
                if(data.status === 201){
                    localStorage.setItem('authnticated', true)
                    localStorage.setItem('email', data.email)
                    history.replace('/Home')
                }else {
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
            })
            .catch(err=>{
                setLoading(false)
                setMassg({
                    open : true,
                    massg : 'Faild to connect to server',
                    severity : 'error'
                })
            })

        }
    }

    return(
        <Container maxWidth="sm" component={Paper} style={{display : 'flex',flexDirection : 'column',marginTop : 100,justifyContent : 'center',alignItems: 'center',padding: 30}}>
        {
            loading ? <LinearProgress style={{width : '100%'}} /> : ''
        }
            {
                sendStatus ? (
                    <div>
                        <TextField
                            variant="outlined"
                            label="Enter otp"
                            fullWidth
                            name="otp"
                            type="number"
                            value={data.otp}
                            onChange={handleChange}
                            style={{
                                marginTop : 10
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Enter New Password"
                            fullWidth
                            name="new_pass"
                            type="text"
                            value={data.new_pass}
                            onChange={handleChange}
                            style={{
                                marginTop : 10
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Confirm Password"
                            fullWidth
                            name="c_pass"
                            type="text"
                            value={data.c_pass}
                            onChange={handleChange}
                            style={{
                                marginTop : 10
                            }}
                        />

                        <Button variant="contained" onClick={finalSubmit} style={{marginTop : 10}}>
                            Change Now
                        </Button>
                    </div>
                ) : (
                    <div>

                        <TextField
                            variant="outlined"
                            label="Enter your email address"
                            fullWidth
                            value={email}
                            onChange={({target : {value}})=>setEmail(value)}
                        />
                        <Button variant="contained" style={{marginTop : 20}} onClick={handleSend} >
                            Continue
                        </Button>
                    </div>
                )
            }
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}