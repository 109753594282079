import React from "react";
import {
    Container, 
    Toolbar,
    IconButton,
    Typography,
    Grid,
    Divider,
    Paper,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Tooltip
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from "react-router";
import { Api } from "../../process/api";
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import SnakBar from "../../consts/massage";
export default function AppointDet (props){
    const history = useHistory()
    const propData = props.location.state
    const [loading, setLoading] = React.useState(false)
    const [appointInfo, setAppointInfo] = React.useState({})
    const [userInfo, setUserInfo] = React.useState({})
    const [physiInfo, setPhysiInfo] = React.useState({})
    const [report, setReport] = React.useState(null)
    const [repOpen, setRepOpen] = React.useState(false)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    const [massg, setMassg] = React.useState({})
    const [refresh,setRefresh] = React.useState()
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'appointMoreDet',{
            method : 'POST',
            body : JSON.stringify({
                appointment_id : propData.id,
                physi_id : propData.physician_id,
                user_id : propData.user_id
            })
        })
        .then(res=>res.json())
        .then(data=>{
            setLoading(false)
            console.log(data.appoint_info)
            setAppointInfo(data.appoint_info)
            setUserInfo(data.user_info)
            setPhysiInfo(data.physician_info)
            setReport(data.report.report)
        })
    },[refresh])

    const onSubmit = ()=>{
        setLoading(true)
        const formData = new FormData
        formData.append('id',propData.id)
        formData.append('report',draftToHtml(convertToRaw(editorState.getCurrentContent())))
        fetch(Api+'updateReport',{
            method : 'POST',
            body : formData
        })
        .then(res=>res.json())
        .then(data=>{
            setLoading(false)
            if(data.status === 201){
                setRepOpen(false)
                setMassg({
                    open : true,
                    massg : data.massg,
                    severity : 'success'
                })
            }else {
                setMassg({
                    open : true,
                    massg : data.massg,
                    severity : 'error'
                })
            }
        })
        .catch(err=>{
            setMassg({
                open : true,
                massg : "Faild to connect to server",
                severity : 'error'
            })
        })
    }

    const sessionStart = (props)=>{
        setLoading(true)
        fetch(Api+'SessionStart&id='+propData.id+'&value='+props+'&token_no='+appointInfo['token no']+'&email='+localStorage.getItem('email'))
        .then(res=>res.json())
        .then(data=>{
            setLoading(false)
            console.log(data)
            if(data.status === 201){
                setRefresh(Math.random())
                setMassg({
                    open : true,
                    massg : data.massg,
                    severity : 'success'
                })
            }else {
                setMassg({
                    open : true,
                    massg : data.massg,
                    severity : 'error'
                })
            }
        })
        .catch(err=>{
            setMassg({
                open : true,
                massg : "Faild to connect to server",
                severity : 'error'
            })
        })    
        
    }
    return(
        <Container>
            <Paper style={{marginBottom: 5}}>
            <Toolbar>
                <IconButton onClick={()=>history.goBack()}>
                    <ChevronLeftIcon />
                </IconButton>
                <Typography>Appointment Details</Typography>
                <Button 
                    variant="contained"
                    style={{marginLeft : 10}}
                    onClick={()=>setRepOpen(true)}
                >
                    Create Report
                </Button>
                {
                    appointInfo.status === 'session started' ? (
                        <Button 
                            variant="contained"
                            style={{marginLeft : 10}}
                            onClick={()=>sessionStart('completed')}
                        >
                            End session
                        </Button>
                    ) : (
                        <Button 
                            variant="contained"
                            style={{marginLeft : 10}}
                            onClick={()=>sessionStart('session started')}
                            disabled={appointInfo.status != 'completed' ? false : true}
                        >
                            Start session
                        </Button>
                    )
                }
            </Toolbar>
            </Paper>
            {
            loading ? <LinearProgress /> : ''
            }
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                <Paper>
                <TableContainer>
                    <TableBody>
                {
                    Object.keys(appointInfo).map((item,index)=>
                        <TableRow key={index}>
                            <TableCell>{item}</TableCell>
                            <TableCell>{appointInfo[item]}</TableCell>
                        </TableRow>
                    
                    )
                }
                    </TableBody>
                </TableContainer>
                </Paper>

                <Paper>
                <h3>Report Details</h3>
                <Divider />
                {
                    report != null ? (
                        <div dangerouslySetInnerHTML={{__html: report}}>

                        </div>
                    ) : (
                        <div>Report not generated yet</div>
                    )
                }
                </Paper>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Paper>
                        <h3>User Details</h3>
                        <Divider />
                        <TableContainer>
                            <TableBody>
                        {
                            Object.keys(userInfo).map((item,index)=>
                                <TableRow key={index}>
                                    <TableCell>{item}</TableCell>
                                    <TableCell>{userInfo[item]}</TableCell>
                                </TableRow>
                            
                            )
                        }
                            </TableBody>
                        </TableContainer>
                    </Paper>
                </Grid>

            </Grid>

            <Dialog
                open={repOpen}
                onClose={()=>setRepOpen(false)}
                maxWidth="xl"
            >
                <DialogTitle
                    onClose={()=>setRepOpen(false)}
                    style={{justifyContent : 'space-between',display : 'flex'}}
                >
                    Create Report

                    <IconButton onClick={()=>setRepOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{border : '1px solid ',minHeight : 200, borderColor : '#e5e5e5'}}
                    toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
                
                    />
                    <textarea
                    disabled
                    style={{display: 'none'}}
                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                    // onChange={({target : {value}})=>setData(value)}
                />
                </DialogContent>

            </Dialog>
            <SnakBar setMassg={setMassg} massg={massg} />
        </Container>
    )
}