import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const SnakBar = ({setMassg, massg})=>{
   
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setMassg({
            ...massg,
            open : false
        });
    };
    return(
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={massg.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert  severity={massg.severity} sx={{ width: '100%' }} onClose={handleClose}>
                    {
                        massg.massg
                    }
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export default SnakBar