
import React from "react";
import { useHistory,withRouter } from "react-router";
function Logout(params) {
    const history = useHistory()
    React.useEffect(()=>{
        localStorage.removeItem('authnticated')
        window.location.replace('/')
    },[])
    
    return null
}
export default withRouter(Logout);