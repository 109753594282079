import React from "react";
import {
    Container,
    Paper,
    TextField,
    Button,
    Typography,
    LinearProgress,
    Box
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import { Api } from "../../process/api";
import SnakBar from "../../consts/massage";
import { useHistory,Link } from "react-router-dom";
export default function Login(){
    const history = useHistory()
    const classes = useStyle();
    const [formData, setFormData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [err, setErr] = React.useState({})
    const [massg, setMassg] = React.useState({
                        massg : '',
                        severity : '',
                        open : false
    })

    const onChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const validate = ()=>{
        let error = {}
        let valid =  true

        if(!formData.user_name){
            valid = false
            error['user_name'] = true
        }
        if(!formData.pass){
            valid = false
            error['pass'] = true
        }

        setErr(error)

        return valid
    }

    const onSubmit = ()=> {

        if(validate()){
            setLoading(true)
            console.log(Api+'physiAuth')
            fetch(Api+'physiAuth',{
                method : 'POST',
                body : JSON.stringify(formData)
            })
            .then(response=>response.json())
            .then(data=>{
                setLoading(false)
                console.log(data)
                if(data.status === 201){
                    localStorage.setItem('authnticated', true)
                    localStorage.setItem('email', formData.user_name)
                    setMassg({
                        massg : data.massg,
                        severity : 'success',
                        open : true
                    })
                    history.replace('/Home')
                }else {
                    setMassg({
                        massg : data.massg,
                        severity : 'error',
                        open : true
                    })
                }
            })
            .catch(err=>{
                setLoading(false)
                setMassg({
                    massg : "Could not connect to the server",
                    severity : 'error',
                    open : true
                })
            })
        }
    }
    return(
        <Container maxWidth="sm" className={classes.Container}>
            <Paper>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
                <Box  className={classes.paper}>
                <h3>Login to HEROASIS Ambassador panel</h3>
                <TextField
                    label="Email address"
                    variant="outlined"
                    fullWidth
                    style={{margin: 10}}
                    name="user_name"
                    error={err.user_name}
                    value={formData.user_name}
                    onChange={onChange}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    style={{margin: 10}}
                    type="password"
                    name="pass"
                    error={err.pass}
                    value={formData.pass}
                    onChange={onChange}
                />
                <Link to="/ForgotPass"  style={{textDecoration : 'none'}}>
                <Button >
                    Forgot password
                </Button>
                </Link>
                <Button 
                    variant="contained" 
                    style={{margin: 10}}
                    onClick={onSubmit}
                    disabled={loading}
                >
                   log in
                </Button>
                </Box>
            </Paper>
             <SnakBar setMassg={setMassg} massg={massg} /> 
            
        </Container>
    )
}

const useStyle = makeStyles({
    paper : {
        padding: 20,
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'center'
    },
    Container : {
        marginTop : 100
    }
})