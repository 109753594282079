import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TodayIcon from '@mui/icons-material/Today';
import GroupIcon from '@mui/icons-material/Group';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';
import {BrowserRouter, Link, Route, Switch,Redirect,useHistory} from 'react-router-dom'
import SettingsIcon from '@mui/icons-material/Settings';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SlotManager from './SlotManger/slotmanager';
import Appointments from './appointment/home_app';
import Settings from './settings/settings';
import Logout from './settings/logout';
import Profile from './profile/profileManager';
import EditProfile from './profile/profileEdit';
import { Api } from '../process/api';
import AppointDet from './appointment/appoint_det';
const drawerWidth = 280;
const navItem = [
    {
        name : 'Appointments',
        details : "Manage user appointments",
        Link : '/Home/',
        Icon : <TodayIcon />,  
    },
    {
      name : 'Slots Manager',
      details : "Manage Physician Slots",
      Link : '/Home/Slots',
      Icon : <TodayIcon />,  
    },
    {
      name : 'Profile Manager',
      details : "Manage your profile",
      Link : '/Home/Profile',
      Icon : <TodayIcon />,  
    },
    {
      name : 'Log Out',
      details : "Admin panel settings",
      Link : '/Home/logout',
      Icon : <SettingsIcon />,  
  }
]
function Home(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(()=>{
    const auth = localStorage.getItem('authnticated')
    if(!auth){
      history.push('/Login')
    }

    fetch(Api+'emailVerify&email='+localStorage.getItem('email'))
    .then(response=>response.json())
    .then(data=>{
      console.log(data)
      if(data === 402){
        history.push('/Login')
        localStorage.removeItem('email')
      }
    })
  },[])

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List onClick={()=>setMobileOpen(false)}>
        {navItem.map((item, index) => (
          <Link key={index} to={item.Link} style={{textDecoration : 'none',color: '#000'}}>
          <ListItem  button >
            <ListItemIcon >
              {item.Icon}
            </ListItemIcon>
            <ListItemText  primary={<Typography sx={{fontSize : 14,fontWeight:500}}>{item.name}</Typography>} secondary={<Typography sx={{fontSize : 12,fontWeight:400,color : '#585858'}}>{item.details}</Typography>}/>
          </ListItem>
          </Link>
        ))}
      </List>
      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <BrowserRouter>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Ambassador Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" style={{ paddingTop: 70,width:'100%' }}>
        <Switch>
        <Route exact path={`${props.match.path}`} component={Appointments}/>
        <Route path={`${props.match.path}/Slots`} component={SlotManager}/>
        <Route path={`${props.match.path}/logout`} component={Logout}/>
        <Route path={`${props.match.path}/Profile`} component={Profile}/>
        <Route path={`${props.match.path}/EditProfile`} component={EditProfile}/>
        <Route path={`${props.match.path}/AppointDet`} component={AppointDet}/>
        </Switch>
      </Box>
    </Box>
    </BrowserRouter>
  );
}

Home.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Home;
