import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, InputBase } from '@mui/material';
import Resizer from "react-image-file-resizer";
import { Api } from '../../process/api';
import SnakBar from '../../consts/massage';
import { CircularProgress } from '@mui/material';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditProfile(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(props.data)
  const [newImage, setNewImage] = React.useState(null)
  const [massg, setMassg] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e)=>{
      setData({
          ...data,
          [e.target.name] : e.target.value
      })
  }

  const onImageChange = (event)=> {
    var fileInput = false;
    if (event.target.files[0]) {
    fileInput = true;
    }
    if (fileInput) {
    try {
     Resizer.imageFileResizer(
        event.target.files[0],
        800,
        800,
        "JPEG",
        60,
        0,
        (uri) => {
            
            setNewImage(uri);
        },
        "file",
        200,
        200
        );

    
    } catch (err) {
        console.log(err);
    }
    }
}

    const onSubmit = ()=>{
        setLoading(true)
        const formData = new FormData
        formData.append('name',data.name)
        formData.append('department',data.department)
        formData.append('about',data.about)
        formData.append('image',newImage)
        formData.append('id',data.id)
        formData.append('profile_name', data.profile)
        formData.append('price', data.price)
        formData.append('email',data.email)
        formData.append('ph_no',data.ph_no)
        formData.append('gender',data.gender)
        formData.append('designation',data.designation)
        formData.append('qualification',data.qualification)
        console.log(Api+'editPhys')
        fetch(Api+'editPhys',{
            method :'POST',
            body : formData
        })
        .then(Response=>Response.json())
        .then(data=>{
            setLoading(false)
            props.onSave()
            setOpen(false)
            if(data.status === 201){
                setMassg({
                    open : true,
                    massg : data.massg,
                    severity : 'success'
                })
            }else {
                setMassg({
                    open : true,
                    massg : data.massg,
                    severity : 'error'
                })
            }
        })
        .catch(err=>{
            setMassg({
                open : true,
                massg : 'Faild to connect to the server',
                severity : 'error'
            })
        })
    }
    
    

  return (
    <div >
      <IconButton onClick={handleClickOpen}>
            <EditIcon />
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Physician
        </BootstrapDialogTitle>
        <DialogContent dividers>
                <TextField
                    variant="outlined"
                    label="Enter Name"
                    style={{margin: 10}}
                    fullWidth
                    name='name'
                    value={data.name}
                    onChange={handleChange}
                    // error={valid.name}
                />
                <TextField
                    variant="outlined"
                    label="Enter Department"
                    style={{margin: 10}}
                    name='department'
                    fullWidth
                    value={data.department}
                    onChange={handleChange}
                    disabled
                    // error={valid.department}
                />
                <TextField
                    variant="outlined"
                    label="Enter Email"
                    style={{margin: 10}}
                    name='email'
                    fullWidth
                    value={data.email}
                    onChange={handleChange}
                    disabled
                    // error={valid.department}
                />
                <TextField
                    variant="outlined"
                    label="Enter Contact No"
                    style={{margin: 10}}
                    name='ph_no'
                    fullWidth
                    value={data.ph_no}
                    onChange={handleChange}
                    // error={valid.department}
                />
                <TextField
                    variant="outlined"
                    label="Gender"
                    style={{margin: 10}}
                    name='gender'
                    fullWidth
                    value={data.gender}
                    onChange={handleChange}
                    // error={valid.department}
                />
                <TextField
                    variant="outlined"
                    label="qualification"
                    style={{margin: 10}}
                    name='qualification'
                    fullWidth
                    value={data.qualification}
                    onChange={handleChange}
                    // error={valid.department}
                />
                <TextField
                    variant="outlined"
                    label="designation"
                    style={{margin: 10}}
                    name='designation'
                    fullWidth
                    value={data.designation}
                    onChange={handleChange}
                    // error={valid.department}
                />
                <TextField
                    variant="outlined"
                    label="Enter Price"
                    style={{margin: 10}}
                    name='price'
                    fullWidth
                    value={data.price}
                    onChange={handleChange}
                    disabled
                    // error={valid.department}
                />
                <TextField
                    variant="outlined"
                    type="file"
                    style={{margin: 10}}
                    fullWidth
                    onChange={onImageChange}
                    // error={valid.image}
                    helperText="Select your profile (best resolution: 900 * 1280)"
                />
                <InputBase
                    placeholder="About the physician"
                    style={{
                        border: '1px solid',
                        // borderColor : valid.about ? 'red' : '#8c8c8c',
                        borderRadius: 5,
                        margin: 10,
                        minHeight : 80,
                        maxHeight : 400,
                        overflow: 'hidden'
                    }}
                    fullWidth
                    multiline={true}
                    value={data.about}
                    name="about"
                    onChange={handleChange}
                    // error={valid.about}

                />
        </DialogContent>
        <DialogActions>
         {
             loading ? <CircularProgress /> : (
                <Button   onClick={()=>onSubmit()}>
                    Save changes
                </Button>
             )
         }
        </DialogActions>
      </BootstrapDialog>

      <SnakBar setMassg={setMassg} massg={massg} />
    </div>
  );
}
